<template>
  <div>
    <div class="columns is-multiline" style="overflow-x: auto;">
      <div
        class="column is-12"
        v-if="this.newMatrix && this.newMatrix.length > 0"
      >
        <table>
          <tr v-for="indexRow in maxRow" :key="indexRow">
            <td
              v-for="indexColumn in maxColumn"
              :key="indexColumn"
              style="overflow-wrap: break-word;min-width: 250px;max-width:250px; padding:5px"
            >
              <span v-if="newMatrix != null">
                <span v-if="indexRow == 1 || indexColumn == 1">
                  <span v-if="newMatrix[indexRow - 1][indexColumn - 1] != null" class="notranslate">
                    {{ newMatrix[indexRow - 1][indexColumn - 1].description }}
                  </span>
                </span>
                <span v-else>
                  <ValidationProvider
                    :vid="`matrix${indexSection}${indexQuestion}${indexColumn - 1}${indexRow - 1}`"
                    name="Text"
                    :rules="{ required: data.isRequired }"
                    v-slot="{ errors }"
                  >
                    <b-field :type="errors.length > 0 ? 'is-danger' : null">
                      <b-input
                        v-model="
                          newMatrix[indexRow - 1][indexColumn - 1].description
                        "
                        @blur="submit(newMatrix[indexRow - 1][indexColumn - 1])"
                        style="min-width:250px;max-width:250px;height:40px;padding-right:5px;padding-top:5px"
                      ></b-input>
                    </b-field>
                    <span class="required">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
              </span>
            </td>
          </tr>
        </table>
        <info-storing-answer :question="data" @resend="$emit('resend-answer', data)"/>
      </div>
    </div>
  </div>
</template>

<script>
import InfoStoringAnswer from '@/components/V2/Candidat/InfoStoringAnswer.vue'
export default {
  components:{
    InfoStoringAnswer,
  },
  props: {
    data: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    indexQuestion: {
      default: () => {},
    },
    sectionId: {
      default: () => {},
    },
  },
  data() {
    return {
      matrix: [],
      isLoad: false,

      maxRow: 0,
      maxColumn: 0,
      newMatrix: [],
    }
  },
  watch:{
    data(val){
      this.matrix = []
      this.newMatrix = []
      this.maxRow = 0
      this.maxColumn = 0
      this.init(val)
    }
  },
  mounted() {
    this.init(this.data)
  },
  methods: {
    init(data){
      this.isLoad = true

      this.matrix = data.answerList
      this.filterTable()
    },
    submit(val) {
      let data = {
        newSectionId: this.sectionId,
        newQuestionId: this.data.id,
        newAnswerId: val,
        description: val.description,
        rating: 0,
        indexQuestion: 0,
        indexAnswer: 0,
        column: val.column,
        row: val.row,
      }
      this.$emit('clicked', data)
    },
    filterTable() {
      this.maxRow = this.matrix.sort((a, b) => b.row - a.row)[0].row
      this.maxColumn = this.matrix.sort((a, b) => b.column - a.column)[0].column
      for (var i = 1; i <= this.maxRow; i++) {
        let y = new Array()
        for (var j = 1; j <= this.maxColumn; j++) {
          var found = this.matrix.find(function(x) {
            return x.row === i && x.column === j
          })
          if (found) {
            y.push(found)
          } else {
            y.push(null)
          }
        }
        this.newMatrix.push(y)
      }
    },
    resetAnswer(){
      this.newMatrix = []
    }
  },
}
</script>
<style lang="scss" scoped>
.matrix {
  max-width: 1032px;
  margin: 0px auto;
}
</style>
